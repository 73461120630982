/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import { taskFor } from 'ember-concurrency-ts';
import { captureException } from '@sentry/browser';

export default class FinStandaloneReportsRoute extends Route {
  @service appService;
  @service router;
  @service reportingMetrics;
  @service reportingViews;
  @service reportingTemplates;
  @service store;

  async beforeModel(transition) {
    let promises = [];
    promises.push(this.reportingMetrics.setup.perform());
    promises.push(this.reportingViews.setUp.perform());
    promises.push(this.reportingTemplates.loadTemplates());
    await Promise.all(promises);
    if (transition.to.name === 'apps.app.standalone.reports.index') {
      let defaultReport = this.reportingViews.standaloneDefaultReport;
      if (!defaultReport) {
        try {
          defaultReport = await taskFor(this.createReport).perform();
          this.store.pushPayload(defaultReport);
        } catch (e) {
          captureException(e);
          console.error(e);
          return await this.router.transitionTo('apps.app.standalone.reports.overview');
        }
      }
      await this.router.transitionTo('apps.app.standalone.reports.report.show', defaultReport.id);
    }
  }

  @dropTask *createReport() {
    return yield post(`/ember/standalone/reporting/create_default_report`, {
      app_id: this.appService.app.id,
    });
  }
}
