/* RESPONSIBLE TEAM: team-actions */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type Transition from '@ember/routing/transition';

export default class CustomAuthenticationTokensRoute extends SettingsRoute {
  @service declare store: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t('settings.custom-authentication-tokens.heading');
  }

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    this.permissionsService.ensurePermissionWhenTransitioning(
      'can_access_developer_hub',
      transition,
    );
  }

  async model() {
    return await this.store.findAll('custom-authentication/token');
  }
}
