/* RESPONSIBLE TEAM: team-data-foundations */
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import AttributeSettingsBase from 'embercom/routes/apps/app/settings/base/attribute-settings-base';

export default class CompaniesRoute extends AttributeSettingsBase {
  @service declare intl: IntlService;

  // For triggering the focus of corresponding attribute description table cell
  queryParams: any = ['attributeForDescriptionEditing'];
  attributeForDescriptionEditing = null;

  get titleToken() {
    return this.intl.t('new-settings.submenu.data.companies');
  }
}
