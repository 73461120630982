/* RESPONSIBLE TEAM: team-workflows */
import type Store from '@ember-data/store';
import Route from '@ember/routing/route';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type Transition from '@ember/routing/transition';
import { FIN_SETUP_BEHAVIOR_ENDPOINT } from 'embercom/components/operator/fin/setup/settings';
import { get } from 'embercom/lib/ajax';
import { states } from 'embercom/models/data/matching-system/matching-constants';
import type IntlService from 'embercom/services/intl';

export default class WorkflowsOverview extends Route {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare session: Session;
  @service declare ticketStateService: TicketStateService;

  titleToken = 'Workflows';

  analytics = {
    place: 'custom-bots-overview',
    section: 'operator',
  };

  get app() {
    return this.appService.app;
  }

  async beforeModel() {
    this.intercomEventService.trackEvent('visited-custom-bots', {
      onMatchingSystem: true,
    });

    await this.loadTicketCustomStates();
  }

  async model(_params: any, transition: Transition) {
    let findDescriptorPromise = async () => {
      return this.store.findAll('conversation-attributes/descriptor');
    };
    let findCustomObjectsPromise = async () => {
      return this.store.findAll('custom-objects/object-type');
    };

    let [descriptors, customObjects, qualificationAttributes, messengerApps, finSetupState] =
      await Promise.all([
        findDescriptorPromise(),
        findCustomObjectsPromise(),
        this.store.findAll('people/qualification-attribute'),
        this.store.findAll('messenger-app'),
        this.fetchFinSetupState(),
        this.store.findAll('workflow-connector/insertable-action'),
        this.store.findAll('instagram/integration').catch(() => {
          this.notificationsService.notifyError(
            this.intl.t('workflows.channels.business-accounts.loading-error'),
          );
        }),
        this.store.query('whatsapp/integration', { no_external_updates: true }),
      ]);

    return hash({
      descriptors,
      customObjects,
      qualificationAttributes,
      messengerApps,
      launchPaywall: transition.to.queryParams.paywall,
      finSetupState,
    });
  }

  async loadTicketCustomStates() {
    this.session.switchWorkspace(this.app.id);
    await this.session.workspace.fetchRequiredAttributes();
    await this.ticketStateService.setup();
  }

  async fetchFinSetupState(): Promise<{ chatIsLive: boolean; emailIsLive: boolean } | null> {
    let [chatSetupRuleset, emailSetupRuleset] = await Promise.all([
      get(FIN_SETUP_BEHAVIOR_ENDPOINT, {
        app_id: this.app.id,
        setup_type: 'chat',
      }),
      get(FIN_SETUP_BEHAVIOR_ENDPOINT, {
        app_id: this.app.id,
        setup_type: 'email',
      }),
    ]);
    if (chatSetupRuleset && emailSetupRuleset) {
      return {
        chatIsLive: chatSetupRuleset.state === states.live,
        emailIsLive: emailSetupRuleset.state === states.live,
      };
    } else {
      return null;
    }
  }
}
