/* RESPONSIBLE TEAM: team-tickets-1 */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
//@ts-ignore

export default class ExternalLinkShieldRoute extends Route {
  @service declare iamService: any;
  @service declare session: Session;

  queryParams = {
    href: { refreshModel: true },
    malicious: { refreshModel: true },
    blocked: { refreshModel: true },
    app_id: { refreshModel: true },
  };

  model(params: any) {
    this.iamService.boot();

    let linkURL = params.href;
    let linkDomain;
    let malicious = params.malicious || false;
    let blocked = params.blocked || false;
    let appId = params.app_id;

    try {
      linkDomain = new URL(params.href).hostname;
    } catch {
      linkDomain = '';
    }

    if (linkDomain.startsWith('www.')) {
      linkDomain = linkDomain.slice(4);
    }

    return {
      linkURL: linkURL.toString(),
      linkDomain,
      malicious,
      blocked,
      appId,
      closeTab: () => window.close(),
    };
  }
}
