/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

export default IntercomRoute.extend({
  store: service(),
  notificationsService: service(),
  developerHub: service(),
  titleToken: alias('developerHub.appPackage.name'),
  model(params) {
    return this.store.findRecord('developer-hub/app-package', params.id).catch((e) => {
      this.notificationsService.notifyError('Sorry that App cannot be found');
      this.transitionTo('apps.app.developer-hub.app-packages');
    });
  },

  afterModel(model) {
    this.set('developerHub.appPackage', model);
  },

  actions: {
    loading(transition) {
      if (!this.controller) {
        return;
      }

      this.controller.set('currentlyLoading', true);
      transition.promise.finally(() => {
        this.controller.set('currentlyLoading', false);
      });
    },
  },
});
