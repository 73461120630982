/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controller-access-in-routes */
import { inject as service } from '@ember/service';
import UsersOrCompaniesRoute from 'embercom/routes/base/users-or-companies-route';
import storage from 'embercom/vendor/intercom/storage';

let CompaniesRoute = UsersOrCompaniesRoute.extend({
  store: service(),
  analytics: {
    section: 'platform',
    place: 'companies',
  },
  isCompany: true,
  model() {
    let app = this.modelFor('apps/app');
    return app.get('companySegments');
  },
  activate() {
    storage.set('lastProductUsed', 'companies');
  },
  deactivate() {
    this.store.unloadAll('company');
  },
  actions: {
    showAllSegment() {
      let segment = this.modelFor('apps/app/companies').get('firstObject');
      segment.revert();
      this.transitionTo('apps.app.companies.segments.segment', segment);
    },
    setSearch(text) {
      this.controller.set('search.rawText', text);
    },
  },
});

export default CompaniesRoute;
