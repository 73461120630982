/* RESPONSIBLE TEAM: team-actions */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';

export default class DeveloperHubAppPackageAppPartnerIndexRoute extends IntercomRoute {
  @service developerHub;

  afterModel() {
    if (!this.developerHub.appPackage.appPartner) {
      this.transitionTo('apps.app.developer-hub.app-packages.app-package.app-partner.edit');
    }
  }
}
