/* RESPONSIBLE TEAM: team-data-foundations */
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

export default class TagsRoute extends BaseSettingsRoute {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get titleToken() {
    return this.intl.t('new-settings.submenu.data.tags');
  }

  async model() {
    return await this.appService.app.loadAndUpdateTags();
  }
}
