/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { get } from 'embercom/lib/ajax';
import { action } from '@ember/object';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';

export default class ContentGapsStandaloneRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare aiAgentSetupService: AiAgentSetupService;

  analytics = {
    section: 'standalone',
    place: 'content-gaps',
  };

  async model() {
    await this.aiAgentSetupService.load();
    let latestQuestionExtractionRunId = await this.loadLastQuestionGroupsRunDetails();
    let finIsLive = this.finIsLive;
    return {
      latestQuestionExtractionRunId,
      finIsLive,
    };
  }

  async loadLastQuestionGroupsRunDetails(): Promise<$TSFixMe> {
    let response = await get(
      '/ember/ai_agent/question_groups/latest_question_extraction_run_details',
      {
        app_id: this.appService.app.id,
      },
    );
    return response.id;
  }

  @action
  refreshModel() {
    this.refresh();
  }

  get finIsLive(): boolean {
    let liveFinWorkflowsCount =
      this.aiAgentSetupService.finStats.workflowsCount.liveFinWorkflowsCount;
    let liveProfilesWithAiAnswersEnabled: boolean =
      this.aiAgentSetupService.liveResolutionBotBehaviors?.contentWrappers?.some(
        (contentWrapper: ContentWrapper) => {
          let content = contentWrapper.contents.firstObject;
          return content?.contentData?.use_ai_answers;
        },
      ) || false;
    return (
      this.aiAgentSetupService.isFinSetupChatRulesetLive ||
      this.aiAgentSetupService.isFinSetupEmailRulesetLive ||
      liveFinWorkflowsCount > 0 ||
      liveProfilesWithAiAnswersEnabled
    );
  }
}
