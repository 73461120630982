/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';

export default IntercomRoute.extend({
  titleToken: 'Canvas Kit',
  appService: service(),
  app: readOnly('appService.app'),
  developerHub: service(),
  appPackage: readOnly('developerHub.appPackage'),
  analytics: { section: 'canvas-kit' },
});
