/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import getTransitionParams from 'embercom/lib/router/get-transition-params';

export default Route.extend({
  beforeModel(transition) {
    let params = getTransitionParams(transition);
    let appPackageCode = params['apps.app.appstore.old-app-package'].id;
    this.transitionTo('apps.app.appstore.app-package', appPackageCode);
  },
});
