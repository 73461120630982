/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

const unsavedChangesMessage = 'Warning: You have unsaved changes. Leave page?';

export default Route.extend({
  titleToken: 'Start guide',
  analytics: { section: 'getting-started-guide' },
  intercomConfirmService: service(),
  cleanUpOnNavigatingAway() {
    this.modelFor(this.routeName).rollbackAttributes();
  },
  activate() {
    window.onbeforeunload = function () {
      if (this.modelFor(this.routeName).hasDirtyAttributes) {
        return unsavedChangesMessage;
      }
    }.bind(this);
  },
  deactivate() {
    window.onbeforeunload = undefined;
  },
  actions: {
    async willTransition(transition) {
      this._super(...arguments);
      if (this.modelFor(this.routeName).hasDirtyAttributes) {
        transition.abort();
        let isConfirmed = await this.intercomConfirmService.confirm({
          title: 'Unsaved Changes',
          body: 'You have unsaved changes. Do you want to discard them?',
          confirmButtonText: 'Discard',
        });
        if (isConfirmed) {
          this.cleanUpOnNavigatingAway();
          return transition.retry().followRedirects();
        } else {
          return true;
        }
      }
      return true;
    },
  },
});
