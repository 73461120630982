/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
import MapBoxLoadingRoute from 'embercom/routes/base/mapbox-loading-route';
import { inject as service } from '@ember/service';

let CompaniesCompanyRoute = MapBoxLoadingRoute.extend({
  store: service(),
  analytics: {
    place: 'company',
  },
  titleToken(model) {
    return model.get('name');
  },
  model(params) {
    return this.store.findRecord('company', params.company_id);
  },
  actions: {
    error(error) {
      if (error?.jqXHR?.status === 404) {
        this.transitionTo('apps.app.companies.company-not-found');
      }
    },
  },
});

export default CompaniesCompanyRoute;
