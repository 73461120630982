/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import {
  parsedESFilters,
  setControllerFilterProperties,
  filterQueryParams,
  setRouteFilterProperties,
} from 'embercom/lib/reporting/flexible/filter-helpers';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';

const QUERY_PARAMS_ES_MAPPINGS = {
  teammateId: 'admin_participant_ids',
  teamsParticipated: 'admin_participant_ids', //a list of teammate ids is passed to ES
  scopingTagIds: 'conversation_tag_ids',
  teammatesAssigned: 'admin_assignee_id',
  teamsAssigned: 'team_assignee_id',
  channels: 'channel_type',
  continents: 'user_location.continent_code',
  countries: 'user_location.country_name',
  customAttributes: 'custom_attributes',
  startedByUser: 'conversation_started_by_user',
  botInboxTime: null, //bot inbox is a setting, it's not passed to ES
  topics: 'topic',
};
const FILTER_TYPES = Object.keys(QUERY_PARAMS_ES_MAPPINGS);

export default class TeamPerformanceRoute extends IntercomRoute {
  @service appService;
  @service store;
  @service reportingService;
  @service reportingMetrics;
  @service customReportsService;

  reportName = 'team-performance';
  titleToken = 'Team performance';
  analytics = {
    place: 'team_performance',
  };
  mergedProperties = ['queryParams'];
  queryParams = filterQueryParams(FILTER_TYPES);
  dashboardIdentifier = 'team-performance';
  chartIdentifiers = ['team-first-response'];
  shouldRedirectFromParent = true;

  get app() {
    return this.appService.app;
  }

  get range() {
    return this.reportingService.range;
  }

  findDataConfig(dataConfigs, chartId) {
    let result = dataConfigs.findBy('chart_identifier', chartId);

    if (!result) {
      result = this.store.createRecord('reporting/chart', {
        dashboard_identifier: this.dashboardIdentifier,
        chart_identifier: chartId,
        app_id: this.app.id,
      });
    }

    return result;
  }

  filters() {
    let queryParamMappings = Object.assign({}, QUERY_PARAMS_ES_MAPPINGS);
    if (!this.app.canUseFeature('conversation_attributes')) {
      delete queryParamMappings.customAttributes;
    }
    if (!this.appService.app.canUseTopicFilter) {
      delete queryParamMappings.topics;
    }

    return parsedESFilters(this, queryParamMappings);
  }

  async beforeModel(transition) {
    super.beforeModel(...arguments);

    if (this.app.canSeeFullR2Experience) {
      this.customReportsService.routeToOverviewPage();
    }

    if (this.app.hasCustomReportsBillingFeature) {
      await this.reportingMetrics.loadMetricsAndProperties();
    }

    //setup filters
    let filters = FILTER_TYPES.filter((filter) => {
      if (filter === 'topics') {
        return this.appService.app.canUseTopicFilter;
      }

      return true;
    });
    setRouteFilterProperties(this, transition.to.queryParams, filters);
  }

  async model(_, transition) {
    let reportingAllSettings = await this.store.queryRecord('reporting/settings', {});
    let botInboxSettings = await this.store.findRecord('operator-bot-inbox-settings', this.app.id);
    let conversationAttributeDescriptors = [];

    let fetchedDataConfigs = await this.store.query('reporting/chart', {
      dashboard_identifier: this.dashboardIdentifier,
    });

    let dataConfigs = this.chartIdentifiers.reduce((result, id) => {
      result[id] = this.findDataConfig(fetchedDataConfigs, id);
      return result;
    }, {});

    if (this.app.canUseFeature('conversation_attributes')) {
      conversationAttributeDescriptors =
        await ConversationAttributeDescriptor.peekAllAndMaybeLoad();
    }

    let settings = {
      reportingExcludeOooHours: reportingAllSettings.get('excludeOooHours'),
      allowToExcludeBotInbox: botInboxSettings.get('assignConversationsToBots'),
      excludeBotInboxStartDate: botInboxSettings.get('assignConversationsToBotsLastEnabledAt'),
      canUseTopicFilter: this.appService.app.canUseTopicFilter,
    };

    return hash({
      settings,
      dataConfigs,
      dashboardIdentifier: this.dashboardIdentifier,
      conversationAttributeDescriptors,
      launchPaywall: transition.to.queryParams.paywall,
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    let { range, reportName, tag } = this;
    let controllerProperties = {
      rangeStart: range.start,
      rangeEnd: range.end,
      range,
      rangeParams: {
        baseAggregations: range.baseAggregations,
        heatmapAggregations: range.heatmapAggregations,
        interval: range.interval,
        comparisonStartMoment: range.comparisonStartMoment,
        comparisonEndMoment: range.comparisonEndMoment,
        startMoment: range.startMoment,
        endMoment: range.endMoment,
        inDays: range.inDays,
        timezone: range.timezone,
      },
      tag,
      reportName,
      ...model,
    };
    controller.setProperties(controllerProperties);
    setControllerFilterProperties(this, controller, FILTER_TYPES);
  }
}
