/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { type KnowledgeUsageSummary } from 'embercom/components/knowledge-hub/overview/types';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { action } from '@ember/object';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';

export default class KnowledgeHubOverviewIndexRoute extends Route {
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare intercomEventService: $TSFixMe;
  @service declare aiAgentSetupService: AiAgentSetupService;

  async model(): Promise<KnowledgeUsageSummary> {
    let [summary] = await Promise.all([
      this.knowledgeHubService.fetchKnowledgeUsageSummary(),
      this.aiAgentSetupService.load(),
    ]);
    return summary;
  }

  @action
  didTransition() {
    this.intercomEventService.trackEvent('knowledge-hub-sources-page-visited');
  }
}
