/* RESPONSIBLE TEAM: team-standalone */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import type ContentImportService from 'embercom/services/content-import-service';
import type Store from '@ember-data/store';

export default class AudienceRoute extends Route {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare aiContentSegmentsService: AiContentSegmentsService;
  @service declare contentImportService: ContentImportService;
  @service declare store: Store;

  async beforeModel() {
    await Promise.all([
      ConversationAttributeDescriptor.peekAllAndMaybeLoad(),
      this.store.findRecord('messenger-settings/look-and-feel', this.appService.app.id),
      this.aiContentSegmentsService.loadSegments(),
      this.contentImportService.fetchContentImportSources(),
    ]);
  }

  get titleToken() {
    return this.intl.t('new-settings.submenu.data.audiences');
  }
}
