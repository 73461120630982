/* RESPONSIBLE TEAM: team-standalone */
import type Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'ember-concurrency';

export default class FinStandaloneIntercomMessengerRoute extends Route {
  @service declare appService: { app: { id: string } };
  @service declare store: Store;

  get app() {
    return this.appService.app;
  }

  async model() {
    return hash({
      operatorIdentity:
        this.store.peekRecord('operator-identity', '1') ||
        this.store.findRecord('operator-identity', '1'),
      messengerSettings:
        this.store.peekRecord('messenger-settings/all', this.app.id) ||
        this.store.findRecord('messenger-settings/all', this.app.id),
    });
  }
}
