/* RESPONSIBLE TEAM: team-actions */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import type Transition from '@ember/routing/transition';

export default class AuthenticationEditRoute extends SettingsRoute {
  @service declare store: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomConfirmService: $TSFixMe;

  async model(params: any) {
    return await this.store.find('custom-authentication/token', params.id);
  }

  get getCurrentModel(): any {
    return this.modelFor(this.routeName);
  }

  @action
  async willTransition(transition: Transition) {
    let confirmOptions = {
      primaryButtonType: 'primary-destructive',
      title: this.intl.t('workflow-connector.builder.modal.title'),
      confirmButtonText: this.intl.t('workflow-connector.builder.modal.confirm-button'),
      cancelButtonText: this.intl.t('workflow-connector.builder.modal.cancel-button'),
      body: this.intl.t('workflow-connector.builder.modal.body'),
    };
    if (this.getCurrentModel.hasDirtyAttributes) {
      transition.abort();
      let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
      if (!isConfirmed) {
        return;
      }
      this.getCurrentModel.rollbackAttributes();
      transition.retry();
    }
  }
}
