/* RESPONSIBLE TEAM: team-knowledge-interop */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import KnowledgeHubApi, {
  type KnowledgeHubApiQueryParams,
} from 'embercom/lib/knowledge-hub/list-api';
import { getOwner } from '@ember/application';
import { ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import { FolderFilterOption } from 'embercom/lib/content-service/search-api';
import { type SortDirection } from 'embercom/lib/knowledge-hub/constants';
import { action } from '@ember/object';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
export default class AllContentRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare intercomEventService: $TSFixMe;
  @service declare aiAgentSetupService: AiAgentSetupService;

  async model(params: KnowledgeHubApiQueryParams): Promise<KnowledgeHubApi> {
    let defaultSearchParams = {
      app_id: this.appService.app.id as string,
      content_wrapper_types: [ContentWrapperType.SUPPORT_CONTENT],
      folder_filter_option: FolderFilterOption.None,
      object_types: this.knowledgeHubService.getSearchObjectTypes(),
      sort_by: 'title',
      sort_direction: 'asc' as SortDirection,
    };

    this.knowledgeHubService.api = new KnowledgeHubApi(getOwner(this), defaultSearchParams, params);

    await Promise.all([this.knowledgeHubService.api.loadPage(), this.aiAgentSetupService.load()]);

    return this.knowledgeHubService.api;
  }

  @action
  didTransition() {
    this.intercomEventService.trackEvent('knowledge-hub-content-page-visited');
  }
}
