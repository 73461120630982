/* RESPONSIBLE TEAM: team-actions */
import SettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';

export default class AuthenticationIndexRoute extends SettingsRoute {
  @service declare store;

  async model() {
    return await this.store.findAll('custom-authentication/token');
  }
}
