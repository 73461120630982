/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import getDefaultSegment from 'embercom/lib/routes/get-default-segment';

let SegmentsIndexRoute = Route.extend({
  beforeModel() {
    let segments = this.modelFor('apps.app.companies');
    let admin = this.modelFor('apps');
    let segment = getDefaultSegment(segments, admin.visible_segment_ids, 'all');
    this.replaceWith('apps.app.companies.segments.segment', segment);
  },
});

export default SegmentsIndexRoute;
