/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import reviewStateMachine from 'embercom/lib/developer-hub/review-state-machine';

export default Route.extend({
  developerHub: service(),
  titleToken: 'Review',
  analytics: { section: 'review' },

  afterModel() {
    let reviewState = reviewStateMachine(this.get('developerHub.appPackage'));
    if (this.routeName !== reviewState.routeTransition) {
      this.transitionTo(reviewState.routeTransition);
    }
  },
});
