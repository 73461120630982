/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
export default Route.extend({
  store: service(),
  analytics: {
    section: 'mailchimp_imports',
    place: 'finish',
  },

  activate() {
    this.controllerFor('apps/app/import/mailchimp').set('currentStep', 3);
  },

  model(params) {
    return this.store.findRecord('mailchimp-import', params.id);
  },
});
