/* RESPONSIBLE TEAM: team-actions */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { inject as service } from '@ember/service';

export default IntercomRoute.extend({
  store: service(),
  developerHub: service(),
  titleToken: 'OAuth',
  analytics: { section: 'oauth_scope' },

  model() {
    return this.store.queryRecord('developer-hub/app-package-install', {
      app_package_id: this.get('developerHub.appPackage.id'),
      only_owner_app: true,
    });
  },
});
