/* RESPONSIBLE TEAM: team-ai-agent */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controller-access-in-routes */
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { hash } from 'rsvp';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import moment from 'moment-timezone';
import { action } from '@ember/object';

export default class CustomerSupportFinAiAgentRoute extends IntercomRoute {
  @service intercomEventService;
  @service intl;
  @service appService;
  @service reportingService;
  @service reportingMetrics;
  @service store;
  @service customReportsService;

  get range() {
    return this.reportingService.range;
  }

  get analytics() {
    return {
      place: 'fin',
    };
  }

  async beforeModel() {
    super.beforeModel(...arguments);

    if (this.appService.app.canSeeFullR2Experience) {
      this.customReportsService.routeToOverviewPage();
    }

    return this.customReportsService.preloadDescriptorsAndTicketTypes();
  }

  async model() {
    let settings = this.reportingSettings;
    let conversationAttributeDescriptors =
      await ConversationAttributeDescriptor.peekAllAndMaybeLoad();

    return hash({ conversationAttributeDescriptors, settings });
  }

  get reportingSettings() {
    return (
      this.store.peekAll('reporting/settings')?.firstObject ||
      this.store.queryRecord('reporting/settings', {})
    );
  }

  @action
  didTransition() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'fin_report',
      context: 'operator',
      start_date: moment(this.controller.rangeStart).format('YYYY/MM/DD'),
      end_date: moment(this.controller.rangeEnd).format('YYYY/MM/DD'),
    });
    this.intercomEventService.trackEvent('fin-reporting-page-visited');
  }
}
