/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  store: service(),
  developerHub: service(),
  titleToken: 'Workspaces',
  analytics: { section: 'workspaces' },

  model() {
    let appPackageId = this.get('developerHub.appPackage.id');
    return this.store.query('developer-hub/app-package-install', {
      app_package_id: appPackageId,
    });
  },
});
