/* RESPONSIBLE TEAM: team-actions */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  store: service(),
  developerHub: service(),

  model() {
    return this.store.query('appstore/category', { category_type: 'default' });
  },

  afterModel() {
    if (!this.get('developerHub.appPackage.appStoreListing')) {
      this.transitionTo('apps.app.developer-hub.app-packages.app-package.app-store-listing.edit');
    }
  },
});
