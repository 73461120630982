/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import type RouterService from '@ember/routing/router';
import type Store from '@ember-data/store';
import { hash } from 'rsvp';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { action } from '@ember/object';
import type Guideline from 'embercom/models/ai-agent/guidelines';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

export default class AutomationFinAIAgentGuidanceRoute extends IntercomRoute {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare intercomConfirmService: IntercomConfirmService;

  analytics = {
    section: 'automation',
    place: 'fin-ai-agent.guidance',
  };

  get titleToken(): string {
    return this.intl.t('ai-agent.sub-menu.guidance.title');
  }

  beforeModel() {
    if (!this.appService.app.canUseFinGuidance) {
      this.router.transitionTo('apps.app.automation.fin-ai-agent.setup');
    }
  }

  get unsavedGuidelines() {
    let guidelines: Guideline[] = this.store.peekAll('ai-agent/guidelines').toArray();
    return guidelines.filter((guideline) => guideline.hasDirtyAttributes || guideline.isDeleted);
  }

  get unsavedGuidelinesWithPrompts() {
    return this.unsavedGuidelines.filter((guideline) => guideline.hasPrompt);
  }

  async model() {
    let guidelines = await this.store.findAll('ai-agent/guidelines');
    await this.knowledgeHubService.fetchKnowledgeUsageSummary();

    return hash({ guidelines });
  }

  @action
  async willTransition(transition: any) {
    if (this.unsavedGuidelinesWithPrompts.length > 0) {
      transition.abort();

      let confirmOptions = {
        title: this.intl.t('operator.fin.setup.personality.unsaved-changes-modal.title'),
        confirmButtonText: this.intl.t(
          'operator.fin.setup.personality.unsaved-changes-modal.confirm',
        ),
        cancelButtonText: this.intl.t(
          'operator.fin.setup.personality.unsaved-changes-modal.cancel',
        ),
        body: this.intl.t('operator.fin.setup.personality.unsaved-changes-modal.body'),
      };
      let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
      if (!isConfirmed) {
        return;
      }
      this.unsavedGuidelines.forEach((guideline) => guideline.rollbackAttributes());
      transition.retry();
    } else if (this.unsavedGuidelines) {
      this.unsavedGuidelines.forEach((guideline) => guideline.rollbackAttributes());
    }
  }
}
