/* RESPONSIBLE TEAM: team-data-foundations */
import BaseSettingsRoute from 'embercom/routes/apps/app/settings/base/base-settings-route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class CustomObjectData extends BaseSettingsRoute {
  @service declare intercomEventService: $TSFixMe;
  @service declare store: $TSFixMe;

  async model() {
    return await this.store.findAll('custom-objects/object-type');
  }

  @action
  didTransition() {
    super.didTransition();
    this.intercomEventService.trackEvent('has_visited_custom_objects');
  }
}
