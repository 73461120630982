/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router';

export default class TestingStandaloneRoute extends Route {
  @service declare appService: any;
  @service declare router: RouterService;

  analytics = {
    section: 'standalone',
    place: 'standalone.testing',
  };

  beforeModel() {
    if (!this.appService.app.canUseFinPlayground) {
      this.router.transitionTo('apps.app.standalone.channels.overview');
    }
  }
}
