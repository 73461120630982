/* RESPONSIBLE TEAM: team-data-foundations */
import IntercomRoute from 'embercom/routes/base/intercom-route';
import { fetchSyncingState } from 'embercom/lib/appstore/unsubscribe-app';
import { inject as service } from '@ember/service';

export default IntercomRoute.extend({
  store: service(),
  permissionsService: service(),

  userSegmentsRoute: 'apps.app.users.segments',

  beforeModel(transition) {
    this._super(...arguments);
    return this.permissionsService.ensurePermissionWhenTransitioning(
      'can_bulk_import_contacts',
      transition,
      this.userSegmentsRoute,
    );
  },

  activate() {
    this.hidePrimaryNav();
  },

  deactivate() {
    this.showPrimaryNav();
  },

  async model() {
    let app = this.modelFor('apps/app');
    let syncingState = await fetchSyncingState(app.id);

    return {
      mailchimpImport: this.store.createRecord('mailchimp-import', {
        appId: app.get('id'),
      }),
      syncingState,
    };
  },
});
