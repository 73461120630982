/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type Settings from 'embercom/models/ai-agent/tone-of-voice-settings';

export default class PersonalityStandaloneRoute extends Route {
  @service declare appService: $TSFixMe;
  @service declare store: Store;

  analytics = {
    section: 'standalone',
    place: 'personality',
  };

  async model() {
    let personalityCustomizationSettings = await this.getPersonalityCustomizationSettings();

    return {
      personalityCustomizationSettings,
    };
  }

  async getPersonalityCustomizationSettings() {
    return (await this.store.findRecord(
      'ai-agent/tone-of-voice-settings',
      this.appService.app.id,
    )) as Settings;
  }
}
