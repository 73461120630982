/* RESPONSIBLE TEAM: team-channels */
import { inject as service } from '@ember/service';
import WorkspaceSettingsRoute from 'embercom/routes/apps/app/settings/base/workspace-settings-route';
import type IntlService from 'embercom/services/intl';
import { hash } from 'rsvp';

export default class BrandsRoute extends WorkspaceSettingsRoute {
  @service declare intl: IntlService;
  @service declare appService: any;

  get titleToken() {
    return this.intl.t(`new-settings.submenu.workspace.brands`);
  }

  async model() {
    return hash({
      emailDomains: this.appService.app.loadEmailDomains(),
    });
  }
}
