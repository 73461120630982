/* RESPONSIBLE TEAM: team-standalone */
import { inject as service } from '@ember/service';
import IntercomRoute from 'embercom/routes/base/intercom-route';
import type RouterService from '@ember/routing/router-service';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';

export default class StandaloneRoute extends IntercomRoute {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare store: any;
  @service declare greatGuidanceService: GreatGuidanceService;

  get app(): any {
    return this.appService.app;
  }

  beforeModel(transition: any): any {
    if (
      transition.to.name.startsWith('apps.app.standalone.setup') ||
      transition.to.name === 'apps.app.standalone.index'
    ) {
      this.router.transitionTo('apps.app.standalone.channels.overview');
    }

    let appsController: any = this.controllerFor('apps/app');
    appsController.set('showPrimaryNav', false);
  }

  redirect(): any {
    if (!this.app.canUseStandalone) {
      return this.router.transitionTo('apps.app', this.app.id);
    }
  }
}
