/* RESPONSIBLE TEAM: team-help-desk-experience */

import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type InboxState from 'embercom/services/inbox-state';
import type InboxVersion from 'embercom/services/inbox-version';
import type Nexus from 'embercom/services/nexus';
import type InboxNexusEventsHandler from 'embercom/services/inbox-nexus-events-handler';
import type Transition from '@ember/routing/-private/transition';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type Inbox2MacrosSearch from 'embercom/services/inbox2-macros-search';
import type KeyboardLayout from 'embercom/services/keyboard-layout';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type ExperimentsApi from 'embercom/services/experiments-api';
import type Inbox2TagsSearch from 'embercom/services/inbox2-tags-search';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';
import type RouterService from '@ember/routing/router-service';
import getTransitionParams from 'embercom/lib/router/get-transition-params';
import type CoreData from 'embercom/services/inbox2-core-data';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type CopilotApi from 'embercom/services/copilot-api';
import type IntlService from 'embercom/services/intl';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import type AdminPingService from 'embercom/services/admin-ping-service';
import type ThemeService from 'embercom/services/theme-service';

const INBOX2_ONBOARDING_ROUTE = 'inbox.workspace.onboarding';
const PRACTICE_SHORTCUTS_ROUTE = 'inbox.workspace.practice-shortcuts';

export default class WorkspaceRoute extends Route {
  @service session!: Session;
  @service themeService!: ThemeService;
  @service declare adminPingService: AdminPingService;
  @service iamService!: any;
  @service inboxState!: InboxState;
  @service inboxVersion!: InboxVersion;
  @service intl!: IntlService;
  @service declare router: RouterService;
  @service declare inboxNexusEventsHandler: InboxNexusEventsHandler;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;
  @service declare inbox2MacrosSearch: Inbox2MacrosSearch;
  @service declare keyboardLayout: KeyboardLayout;
  @service declare nexus: Nexus;
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare experimentsApi: ExperimentsApi;
  @service declare copilotApi: CopilotApi;
  @service declare inbox2TagsSearch: Inbox2TagsSearch;
  @service declare inbox2CoreData: CoreData;
  @service declare ticketStateService: TicketStateService;
  @service declare inboxSidebarService: InboxSidebarService;

  buildRouteInfoMetadata() {
    return {
      responsibleTeam: 'team-help-desk-experience',
    };
  }

  get analytics() {
    return {
      app_id_code: this.session.workspace.id,
      admin_id: this.session.teammate.id,
      inbox_version: 2,
      ...this.copilotApi.betaAnalyticsData,
    };
  }

  async beforeModel(transition: Transition) {
    let routeParams = getTransitionParams(transition) as {
      'inbox.workspace': { workspace_id: string };
    };
    let workspaceId = routeParams['inbox.workspace'].workspace_id;

    this.session.switchWorkspace(workspaceId);

    // For now, we've decoupled fetchFeatures from isFeatureEnabled, but that
    // means we have to load feature flags upfront somewhere in the route
    // hierarchy to guarantee that the correct values are always available to
    // us.
    //
    // There may be a quicker way to do this without blocking the route.
    // If there is, we can remove this call from here.
    await Promise.all([
      this.session.getTeammate(workspaceId),
      this.session.workspace.fetchRequiredAttributes(),
      // Uncomment below code when running an experiement within Inbox 2
      this.experimentsApi.loadExperiments(),
    ]);

    if (
      this.session.workspace.isFeatureEnabled('team-standalone-fin-standalone') &&
      !this.session.workspace.isFeatureEnabled(
        'team-standalone-allow-access-outside-standalone-section',
      )
    ) {
      transition.abort();
      this.router.transitionTo('apps.app.standalone', workspaceId);
    }
  }

  async model(_params: any, transition: Transition) {
    this.cardlessTrialService.initializeUsingWorkspace(this.session.workspace);

    let redirected = this.redirectToMentionsInboxIfCollaboratorAccessingOnboarding(transition);
    if (redirected) {
      return;
    }

    this.iamService.boot(this.session.workspace, this.session.teammate);

    this.adminPingService.start(
      this.session.workspace.id,
      this.session.teammate.id,
      this.session.workspace.isFeatureEnabled('team-sops-teammate-status-auto-away-mode'),
    );

    this.themeService.setupTheme(this.session.workspace.id);
    await this.intl.switchLocale(this.session.teammate.locale);
    await this.ticketStateService.setup();
    await this.keyboardLayout.loadKeyboardLayout();
    return {
      teammate: this.session.teammate,
      workspaceId: this.session.workspace.id,
    };
  }

  async afterModel() {
    if (!this.session.showLightInbox) {
      this.inbox2MacrosSearch.loadAllMacros();

      if (!this.inbox2CoreData.isEnabled) {
        this.inbox2TagsSearch.loadAllTags();
      }
    }
    this.inbox2AssigneeSearch.loadAllAdminsAndTeams();
    await this.copilotApi.setup();
    this.inboxSidebarService.resetDefaults();

    if (this.inbox2CoreData.isEnabled) {
      this.inbox2CoreData.boot();
    } else if (this.session.workspace.isFeatureEnabled('inbox-idb-core-data-compatibility-tests')) {
      this.inbox2CoreData.runTests();
    }
  }

  title(tokens: string[]) {
    return [
      ...tokens,
      this.intl.t('inbox.page-title.help-desk'),
      this.session.workspace.name,
      'Intercom',
    ].join(' | ');
  }

  async activate() {
    this.inboxVersion.setVersion(2);
    document.documentElement.classList.add('inbox2');

    this.nexus.start();
    await this.inboxNexusEventsHandler.startEventHandling();
  }

  deactivate() {
    this.inboxVersion.unsetVersion();
    document.documentElement.classList.remove('inbox2');

    this.inboxNexusEventsHandler.stopEventHandling();
    this.nexus.stop();
    this.adminPingService.stop();
  }

  private redirectToMentionsInboxIfCollaboratorAccessingOnboarding(
    transition: Transition,
  ): boolean {
    if (
      this.session.showLightInbox &&
      (transition.to.name === INBOX2_ONBOARDING_ROUTE ||
        transition.to.name === PRACTICE_SHORTCUTS_ROUTE)
    ) {
      this.replaceWith('inbox.workspace.inbox.inbox', InboxCategory.Shared, InboxType.Mentions);
      return true;
    }

    return false;
  }
}
