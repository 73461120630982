/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
import { inject as service } from '@ember/service';
import ModalNavigationWarningRoute from 'embercom/routes/base/modal-navigation-warning';
import SegmentSetupModelAndController from 'embercom/routes/mixins/users-and-companies/segment-setup-model-and-controller';
import storage from 'embercom/vendor/intercom/storage';
import { action } from '@ember/object';

export default ModalNavigationWarningRoute.extend(SegmentSetupModelAndController, {
  pubsub: service(),
  gtmService: service(),
  intercomEventService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  router: service(),

  titleToken: 'Users',
  companiesOrUsersRoute: 'apps.app.users',

  activate() {
    this._super();
    storage.set('lastProductUsed', 'users');
  },
  afterModel(model) {
    this._super(...arguments);
    let eventName = model.get('is_predefined')
      ? 'applied-segment-to-user-list'
      : 'applied-custom-segment-to-user-list';
    this.intercomEventService.trackEvent(eventName);
  },
  showSegment: action(function (segment) {
    this.send('addSegmentToList', segment);
    this.transitionTo('apps.app.users.segments.segment', segment.identifier);
  }),

  actions: {
    willTransition(transition) {
      let path = this.get('currentModel.identifier');
      return this.conditionallyTransition(transition, path);
    },
    createSegment(segment) {
      this.intercomEventService.trackEvent('user-list-segment-created');
      this.modalService.openModal('users/segments/segment/modals/segment', segment, {
        showSegment: this.showSegment,
      });
    },
    addFiltersToUrl(segment) {
      this.addFiltersToUrl(segment);
    },
  },
});
